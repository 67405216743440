.breadcrumb a,
.breadcrumb-item.active,
.breadcrumb-item + .breadcrumb-item::before{
    color: var(--text-light)
}

.pagination {
    --bs-pagination-color: #000;
    .active{
    --bs-pagination-active-bg: #222;
    --bs-pagination-active-border-color:#000;
    --bs-pagination-hover-color:#000;
    --bs-pagination-color:#000;
    }
}