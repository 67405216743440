.rhap_container{
    background-color: var(--audio-player-background-color);
    color: var(--audio-player-color);
    box-shadow: none;
}
#playerPlaylist{

    .rhap_container{
        border-radius: 0 !important;
    }
    
}
.audioPlayerTicker{
    background-color: var(--audio-player-background-color);
    color: var(--audio-player-color);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: var(--panel-radius);
    border-top-right-radius: var(--panel-radius);
    margin-bottom:-1px;
}

.playlist_window {
    background: rgb(51,51,51);
    /*background: -moz-linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#333333",endColorstr="#000000",GradientType=1);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    color: var(--text-light);
    height: 150px;

    .playlistHeading{
        display: flex;
        font-weight: bold;
        background-color: var(--light-blue);
        .col, .col-12{
            &:last-child{
                text-align: right;
            }
        }
        &:hover, &:active, &:focus{
            background-color: var(--light-blue);
        }
    }
    ul{
        padding-left: 0;
        margin-bottom: 0;
        li{
            
            span{         
                display:inline-block;   
            }
            padding:10px 20px;
            list-style: none;
            border-bottom:2px solid #ccc;
            color: var(--text-light);
            text-decoration: none;
            font-size: 1em;
            .btn-link{
                --bs-btn-color: var(--light-blue);
                padding: 0;
                vertical-align: unset;
            }
            .track_name{
                padding-right: 10px;
            }
            span.trackDuration{
                float: right;
                padding: 2px 0;
            }
            .date_posted{
                padding-left: 10px;
                padding-right: 10px;
            }
            .fa-volume-high{
                color:cornflowerblue;
            } 
            &:last-child{
                border-bottom:2px solid var(--light-blue);
            }
            &.active{
                background-color: #000;
                transition: background-color 0.3s;
                display: block;
                a{
                    font-weight: bold;
                }
            }
            &:hover{
                background-color: #333;
                transition: background-color .5s;
            }
        }
    }
    
}

.sortButton{
    &:hover{
        background-color: var(--light-blue);
        transition: background-color 0.3s;
    }
}


.bottom_bar{
    background-color: #333;
    color: var(--text-light);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.rhap_container{
    border-radius: var(--panel-radius);
}
@include media-breakpoint-up(md) {
    .playlist_window ul li span.trackDuration{
        float: left;
    }
    .playlist_window{
        overflow-x: hidden;
    }
}
@include media-breakpoint-up(xxl) {
    .playlistHeading{
        div:last-child{
            text-align: right;
        }
    }
    .playlist_window ul li span.trackDuration{
        float:right;
    }
}