.shareBar{
    a{
        font-size: 2em;
        padding-left: 10px;
        color:var(--text-light);
        &:hover{
            color:#555;
        }
    }
}
#ShareEpisode{
    text-align: center;
    .shareBar{
        margin: 0 auto;
    }
    .row .col button{
        padding: .5em !important;
    }
}
.ep_mod_title{
    padding-bottom: 0em;
    display: block;
}