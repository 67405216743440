.episode_panel, .standard_panel{
    background: rgb(31,72,140);
    background: rgb(101,129,166);
    background: linear-gradient(315deg, var(--light-blue) 0%, rgba(51,51,51,1) 59%);
    box-shadow: 1px 2px 4px #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f488c",endColorstr="#000000",GradientType=1);
    .episodeTitle,
    .episodeTitle a{
        color: var(--light-blue);
        text-shadow: 1px 2px 5px #000;
        text-decoration: none;
    }
}

.ep_mod_title{
    @extend .h3;
    @extend .px-2;
    font-family: var(--dec-font);
    color: var(--light-blue);
    text-shadow: 1px 2px 2px #000;
}

.transcriptWindow{
    overflow-y: auto;
    max-height: 400px;
}
.episode_audio{
    margin: 1em 0;
    .row{
        border-radius: 10px;
        background-color: var(--audio-player-background-color);
        .summaryPanel{
            background-color: #fff;
            color:#000;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            font-size: 14px;
            line-height: 1.2;
            &.full{
                border-top-right-radius: var(--panel-radius);
                border-bottom-right-radius: var(--panel-radius);
            }
        }
        .audioPanel{
            div{
                text-align: center;
                margin-bottom: 0;
            }
            .rhap_time{
                color: var(--text-light);
            }
            .rhap_additional-controls{ 
                button{
                    display: none;
                }
                padding-right: 3em;
            }
        }
    }
    



}

.filter-controls{
    color: var(--text-light);
}

.tagStyle{
    margin: 5px 0;
    padding: 2px 5px;
    display: inline-block;
    background-color: var(--light-blue);
    margin-left:5px;
    border:2px solid #333;
    a{
        color:#fff;
    }
}
.tagLabel{
    margin: 5px 0;
    padding: 2px 5px;
}
.data_posted{
    font-size: 1.2em;
    padding-left: 10px;
    font-family: var(--dec-font);
    background-color:var(--light-blue);
    color: var(--text-light);
    text-shadow: 1px 2px 2px #000;
    margin-left: -.5em;
    margin-top: .5em;
    padding: 2px 0px 2px 18px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
@include media-breakpoint-up(xs) {
    .tagContainer{
        text-align: left;
    }
    .data_posted{
        width: 50%;
    }
}
@include media-breakpoint-up(md) {
    .tagContainer{
        text-align: right;
    }
    .data_posted{
        width: 25%;
    }
}
@include media-breakpoint-up(lg) {
    .data_posted{
        width: 30%;
        max-width: 180px;
        box-shadow: 5px 5px 5px #000;
    }

}
.episodeHeader{
    float: left;
}