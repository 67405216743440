.navbar-brand, .navbar-nav, .episodeTitle{
    font-family: var(--dec-font);
    font-optical-sizing: auto;
    color: var(--text-light);

}

a.nav-link, .link-light {
    color: var(--text-light) !important;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover,
    &.active,
    &:focus{
        color: var(--light-blue) !important;
    }
}

.navbar{
    --bs-navbar-padding-y:0;
}
@include media-breakpoint-up(lg) {
    .navbar-brand{
        font-size:2em;
    }
}


.navbar-collapse{
    flex-grow: 0;
}

.crown_icon{
    width: 85px;
    margin-top: -5px;
}