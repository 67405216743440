@include media-breakpoint-up(xs) {
    .content{
        background: url('../img/wagu-logo-header.png') #333;
        background-size: 300px;
        background-position: center 40px;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
}

@include media-breakpoint-up(lg) {
    .content{
        background-size: 35%;
        background-position: 0px 70px;
    }
}

@include media-breakpoint-up(xl) {
    .content{
        background-size: 33.33%;
    }
}

@include media-breakpoint-up(xxl) {
    .content{
        background-size: 32%;
    }
}