.carousel-control-prev, .carousel-control-next{
    color:#000;
}

@include media-breakpoint-up(xs) {
    .home-slider{
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(153,153,153,1) 20%);
        margin-bottom: 1em;
        box-shadow: 1px 2px 10px #000;
    }
}

@include media-breakpoint-up(lg) {
    .home-slider{
        .carousel-item img{
            max-width: 50%;
        }
    }
}