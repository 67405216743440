$numberOfPanels:6;
$panelsPerRow:2;

#featuredEpisodes{
    .row{
        .col, .col-12{
            div{
                display: block;
                min-height: 100px;
                margin: 2px;
                padding: 1em;
                font-family: var(--dec-font);
                span:first-child{
                    float: left;
                }
                span:nth-child(2){
                    float: right;
                }
                
            }
            
        }
        --bs-gutter-x:0;
    }
    padding-bottom: 2em;
}

@include media-breakpoint-up(xs) {
    #featuredEpisodes{
        .row{
            .col, .col-12{
                &:nth-child(odd){
                    div{
                        background-color: var(--dark-red);
                        color: var(--text-light);
                    }

                }
                &:nth-child(even){
                    div{
                        background-color: var(--text-light);
                        color: var(--dark-red);   
                    }
                }
                div{
                    &:hover, 
                    &:active, 
                    &:focus{
                        background-color: var(--light-blue);
                        color: var(--text-light);
                    }
                }  
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    #featuredEpisodes{
        .row{
            .col, .col-12{
                &:nth-child(1),
                &:nth-child(4){
                    div{
                        background-color: var(--dark-red);
                        color: var(--text-light);
                    }
                }
                &:nth-child(2),
                &:nth-child(3){
                    div{
                        background-color: var(--text-light);
                        color: var(--dark-red);   
                    }
                }
                @include panel_corner_cut($numberOfPanels, $panelsPerRow, div);
                

                div{
                    &:hover, 
                    &:active, 
                    &:focus{
                        background-color: var(--light-blue);
                        color: var(--text-light);
                    }
                }  
            }
        }
    }

}