#faq_page{
    h2{
        color:var(--text-light);
        background-color: var(--dark-red);
        margin-left:-2em;
        padding-left:2em;
        padding-top:5px;
        padding-bottom: 5px;
        border-radius: 5px;
    }
}