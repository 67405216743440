.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    background-color: #333;
    color: white;
    padding: 1rem;
    text-align: center;
}

.content {
    flex: 1;
    padding: 1rem;
    background-color: #f4f4f4;
}

.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem;
}
