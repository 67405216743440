@use "sass:math";

@mixin panel_corner_cut($numberOfPanels, $panelsPerRow, $mychild) {
    $newVal: math.div($numberOfPanels - $panelsPerRow, 1);
    $incVal: math.div($newVal + 1, 1);

    &:first-child {
        #{$mychild} {
            border-top-left-radius: var(--panel-radius);
        }
    }
    &:last-child {
        #{$mychild} {
            border-bottom-right-radius: var(--panel-radius);
        }
    }
    &:nth-child(#{$panelsPerRow}) {
        #{$mychild} {
            border-top-right-radius: var(--panel-radius);
        }
    }
    @if $newVal > 0 {
        &:nth-child(#{$incVal}) {
            #{$mychild} {
                border-bottom-left-radius: var(--panel-radius);
            }
        }
    }
}